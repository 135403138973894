<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getSystemPage"
      :deleteFun="deleteSystemInfo"
      :createFun="createSystemInfo"
      :updateFun="updateSystemInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getSystemPage, createSystemInfo, updateSystemInfo, deleteSystemInfo } from '@/api/system'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        { label: '机构名称', name: 'name', fieldType: '', queryType: '%' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '机构名称',
          dataIndex: 'name'
        },
        {
          title: '公司网址',
          dataIndex: 'website'
        },
        {
          title: '手机',
          dataIndex: 'mobile'
        },
        {
          title: '公司邮箱',
          dataIndex: 'email'
        },
        {
          title: '地址',
          dataIndex: 'address'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写机构名称', trigger: 'blur' }
        ]
      },
      modelTitle: '机构管理',
      formFields: [
        {
          label: '机构名称', name: 'name', type: 'default'
        },
        {
          label: '公司网址', name: 'website', type: 'default'
        },
        {
          label: '手机', name: 'mobile', type: 'default'
        },
        {
          label: '公司邮箱', name: 'email', type: 'default'
        },
        {
          label: '地址', name: 'address', type: 'default', span: 24
        },
        {
          label: '租赁备注', name: 'remark', type: 'textarea', span: 24
        }
      ],
      moduleName: 'company'
    }
  },
  methods: {
    getSystemPage,
    createSystemInfo,
    updateSystemInfo,
    deleteSystemInfo
  }
}
</script>
